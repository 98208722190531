<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#3F033B"
      spinner="bar-fade-scale"
    />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showsnackbar" color="#3F033B" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: white">
            {{ msg }}
          </span>
        </v-flex>

        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: #000">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap>
      <v-flex xs12>
        <v-layout wrap>
          <v-flex xs12 sm4 md8 text-left>
            <span class="mainHeader">STOCKS</span>
          </v-flex>
          <v-flex xs12 sm4 md2 pr-1 text-right>
            <v-autocomplete
              class="rounded-xl"
              background-color="white"
                :items="supplierList"
                v-model="sName"
                placeholder="Select Supplier"
                item-text="supplierName"
                item-value="_id"
                outlined clearable
                hide-details color="#3F053C"
                dense
              ></v-autocomplete>
      </v-flex>
      <v-flex xs12 sm4 md2 text-right>
        <v-select
                v-model="designId"
                :items="designList"
                outlined
              class="rounded-xl"
              background-color="white"
                hide-details
                dense
                box clearable
                chips color="#3F053C"
                label="Select design"
                item-value="_id"
                item-text="description"
                :filter="customFilter"
              >
                <template slot="selection" slot-scope="data">
                  <v-chip :selected="data.selected" class="chip--select">
                    {{ data.item.name }} - {{ data.item.itemId.name }}
                  </v-chip>
                </template>
                <template slot="item" slot-scope="data">
                  <v-list-tile-content>
                    <v-list-tile-title
                      v-html="data.item.name + ' - ' + data.item.itemId.name"
                    ></v-list-tile-title>
                  </v-list-tile-content>
                </template>
              </v-select>
      </v-flex>
        </v-layout>
      </v-flex>
      <v-flex xs12 py-4 text-left>
        <v-card
          tile
          class="rounded-lg"
          elevation="0"
          :color="$route.name == 'Dashboard' ? 'transparent' : '#FFFFFF'"
          :flat="$route.name == 'Dashboard' ? true : false"
        >
        </v-card>
        <v-layout wrap>
          <v-flex xs12>
            <v-card
              tile
              class="rounded-lg"
              elevation="0"
              :color="$route.name == 'Dashboard' ? 'transparent' : '#FFFFFF'"
              :flat="$route.name == 'Dashboard' ? true : false"
            >
              <v-card elevation="0">
                <v-layout wrap>
                  <v-flex xs12>
                    <v-layout wrap pa-4 v-if="array">
                      <v-flex xs12 v-if="array.length>0">
                        <v-simple-table>
                          <template v-slot:default>
                            <thead>
                              <tr>
                                <th
                                  class="text-left tablefont"
                                  style="font-size: 15px; color: black"
                                >
                                  <b>S.No.</b>
                                </th>
                                <!-- <th
                                  class="text-left subhed"
                                  style="font-size: 15px; color: black"
                                >
                                  <b>Category</b>
                                </th>
                                <th
                                  class="text-left subhed"
                                  style="font-size: 15px; color: black"
                                >
                                  <b>Item</b>
                                </th>
                                <th
                                  class="text-left subhed"
                                  style="font-size: 15px; color: black"
                                >
                                  <b>Design</b>
                                </th> -->
                                <th
                                  class="text-left tablefont"
                                  style="font-size: 15px; color: black"
                                >
                                  <b>Category</b>
                                </th>
                                <th
                                  class="text-left tablefont"
                                  style="font-size: 15px; color: black"
                                >
                                  <b>Item Name</b>
                                </th>
                                <th
                                  class="text-left tablefont"
                                  style="font-size: 15px; color: black"
                                >
                                  <b>Design Name</b>
                                </th>
                                <!-- <th
                                  class="text-left tablefont"
                                  style="font-size: 15px; color: black"
                                >
                                  <b>Supplier Name</b>
                                </th> -->
                                <th
                                  class="text-left tablefont"
                                  style="font-size: 15px; color: black"
                                >
                                  <b>Purchase Date</b>
                                </th>
                                <th
                                  class="text-left tablefont"
                                  style="font-size: 15px; color: black"
                                >
                                  <b>Count</b>
                                </th>
                                <!-- <th
                                  class="text-left tablefont"
                                  style="font-size: 15px; color: black"
                                >
                                  <b>Mk.chg </b>
                                </th>
                                <th
                                  class="text-left tablefont"
                                  style="font-size: 15px; color: black"
                                >
                                  <b>St.pr</b>
                                </th>
                                <th
                                  class="text-left tablefont"
                                  style="font-size: 15px; color: black"
                                >
                                  <b>St.wt</b>
                                </th> -->
                                <th
                                  class="text-left tablefont"
                                  style="font-size: 15px; color: black"
                                >
                                  <b>Net.wt</b>
                                </th>
                                <th v-if="currentType=='false'"
                                  class="text-left tablefont"
                                  style="font-size: 15px; color: black"
                                >
                                  <b>Type</b>
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                v-for="(value, i) in array"
                                :key="i"
                                class="table"
                              >
                                <!-- <td>{{ i + 1 }}</td> -->
                                <td>
                                
                                  <span v-if="page == 1">
                          {{ i + 1 }}
                        </span>
                        <span v-else>
                          {{ i + 1 + 20 * (page - 1) }}
                        </span>
                                </td>
                                <!-- <td>
                                  <span v-if="value.designId.categoryId.name">{{
                                    value.designId.categoryId.name
                                  }}</span>
                                  <span v-else>-</span>
                                </td>
                                <td>
                                  <span v-if="value.designId.itemId.name">{{
                                    value.designId.itemId.name
                                  }}</span>
                                  <span v-else>-</span>
                                </td>
                                <td>
                                  <span v-if="value.designId.name">{{
                                    value.designId.name
                                  }}</span>
                                  <span v-else>-</span>
                                </td> -->
                                <td>
                                  <span v-if="value._id.design.categoryId">{{
                                    value._id.design.categoryId.name
                                  }}</span>
                                  <span v-else>-</span>
                                </td>
                                <td>
                                  <span v-if="value._id.design.itemId">{{
                                    value._id.design.itemId.name
                                  }}</span>
                                  <span v-else>-</span>
                                </td>
                                <td>
                                  <span v-if="value._id.design">{{
                                    value._id.design.name
                                  }}</span>
                                  <span v-else>-</span>
                                  <span v-if="value._id.itemPerWeight"
                                    >-{{ value._id.itemPerWeight }}gm</span
                                  >
                                </td>
                                <!-- <td>
                                  <span v-if="value._id.supplier">{{
                                    value._id.supplier.supplierName
                                  }}</span>
                                  <span v-else>-</span>
                                </td> -->

                                <td>
                                  <span v-if="value.lastPurchaseDate">{{
                                    formatDate(value.lastPurchaseDate)
                                  }}</span>
                                  <span v-else>-</span>
                                </td>
                                <!-- <td>
                                  <span v-if="value.makingCharge">{{
                                    value.makingCharge.toFixed(2)
                                  }}</span>
                                  <span v-else>-</span>
                                </td>
                                <td>
                                  <span v-if="value.stonePrice">{{
                                    value.stonePrice
                                  }}</span>
                                  <span v-else>-</span>
                                </td>
                                <td>
                                  <span v-if="value.stoneWeight">{{
                                    value.stoneWeight
                                  }}</span>
                                  <span v-else>-</span>
                                </td> -->
                                <td>
                                  <span v-if="value.quantity">{{
                                    value.quantity
                                  }}</span>
                                  <span v-else>0</span>
                                </td>
                                <td>
                                  <span v-if="value.netWeight">{{
                                    value.netWeight
                                  }}</span>
                                  <span v-else>0</span>
                                </td>
                                <td v-if="currentType=='false'">
                                  <span v-if="value._id.taxType==false">Demo</span>
                                  <span v-else>GST</span>
                                </td>
                                
                                <!-- <td>
                        <span v-if="value.subRoleId">
                          {{ value.subRoleId.roles }}</span
                        >
                        <span v-else>-</span>
                      </td>
                       <td>
                        <span v-if="value.staffCode">
                          {{ value.staffCode }}</span
                        >
                        <span v-else>-</span>
                      </td>
                      <td>
                      
                        <v-btn v-if="value.staffStatus == 'Inactive'" @click="(changestatus2 = true),(statusId= value._id)" small block color="red" dark>
                          Inactive
                        </v-btn>
                        <v-btn v-if="value.staffStatus == 'Expire'" small block color="grey" dark>
                          Expired
                        </v-btn>
                        <v-btn v-if="value.staffStatus == 'Active'" @click="(changestatus = true),(statusId= value._id)" small block color="green" dark>
                          Active
                        </v-btn>
                      </td>
                      <td>
                        <v-icon
                          size="18" color="#3F053C"
                          class="pr-2" title="Edit"
                          style="cursor: pointer"
                          @click="(curItem = value), (editdialogue = true)"
                          >mdi-pencil</v-icon
                        >
                        <v-icon
                          size="18" color="#3F053C" title="Delete"
                          style="cursor: pointer"
                          @click="(deletedialog = true), (curId = value._id)"
                          >mdi-delete</v-icon
                        >
                      </td> -->
                              </tr>
                            </tbody>
                          </template>
                        </v-simple-table>
                      </v-flex>
                      <v-flex xs12  v-else pa-4 align-self-center text-center class="tablefont1">
              No data found
              </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </v-card>
            </v-card>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
    <v-layout wrap v-if="array">
      <v-flex xs12 pt-4 v-if="array.length>0">
        <v-pagination
                small
                color="#3F053C"
                v-model="page"
                :length="Pagelength"
              >
              </v-pagination>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      appLoading: false,
      ServerError: false,
      showsnackbar: false,
      timeout: 2000,
      msg: null,
      to: null,
      page:1,
      Pagelength:null,
      menu1: false,
      menu2: false,
      supplierList: [],

      currentpage: 1,
      sName: "",
      jewelleryId: localStorage.getItem("id"),
      currentType: localStorage.getItem("GSTstatus"),
      array: [],
      limit: 12,
      username: null,
      name: null,
      address: null,
      phone: null,
      email: null,
      designList: [],
      designId: null,
      from: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
        taxtypes:["GST","DEMO"],
        taxType:"",
    };
  },

  watch: {
    from() {
      this.stocklist();
    },
    to() {
      this.stocklist();
    },
    page() {
      this.stocklist();
    },
    sName() {
      this.stocklist();
    },
    designId() {
      this.stocklist();
    },
    taxType(){
      this.stocklist();
    }
  },
  mounted() {
    this.stocklist();
    this.getSupplier();
    this.getDesign();
  },
  methods: {
    customFilter(item, queryText) {
      const textOne = item.function.toLowerCase();
      const textTwo = item.description.toLowerCase();
      const searchText = queryText.toLowerCase();
      return (
        textOne.indexOf(searchText) > -1 || textTwo.indexOf(searchText) > -1
      );
    },
    validateInput() {
      if (!this.email) {
        this.msg = "Please provide email";
        this.showsnackbar = true;
        return;
      } else if (!this.name) {
        this.msg = "Please provide name";
        this.showsnackbar = true;
        return;
      } else if (!this.phone) {
        this.msg = "Please provide phone";
        this.showsnackbar = true;
        return;
      } else if (!this.username) {
        this.msg = "Please provide username";
        this.showsnackbar = true;
        return;
      } else if (!this.address) {
        this.msg = "Please provide address";
        this.showsnackbar = true;
        return;
      } else {
        this.stocklist();
      }
    },
    stocklist() {
      axios({
        url: "/stock/getalllist",
        method: "POST",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: {
          // username: this.username,
          // email: this.email,
          // jewelleryName: this.name,
          taxType: this.taxType,
          designId: this.designId,
          limit: 20,
          page: this.page,
          supplierId: this.sName,
          jewelleryId: this.jewelleryId,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.array = response.data.data;
            this.Pagelength = Math.ceil(response.data.totalLength / 20);
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    getSupplier() {
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/stock/supplier/getlist",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.supplierList = response.data.supplierlist;
          } else {
            this.snackbar = true;
            this.msg = response.data.msg;
          }

          // this.msg = response.data.msg;

          // this.snackbar = true;
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
    getDesign() {
      this.appLoading = true;
      axios({
        method: "post",
        url: "/design",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: {
          jewelleryId: this.jewelleryId,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.designList = response.data.alldesignlist;
          } else {
            this.snackbar = true;
            this.msg = response.data.msg;
          }

          // this.msg = response.data.msg;

          // this.snackbar = true;
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
    formatDate(item) {
      var dt = new Date(item);
      var day = dt.getDate();
      var year = dt.getFullYear();
      // var hours = dt.getHours();
      // var minutes = dt.getMinutes();
      dt = dt.toString();
      // var ampm = hours >= 12 ? "pm" : "am";
      // hours = hours % 12;
      // hours = hours ? hours : 12;
      // minutes = minutes < 10 ? "0" + minutes : minutes;
      var strTime = day + " " + dt.slice(4, 7) + " " + year;
      //  +
      // " , " +
      // hours +
      // ":" +
      // minutes +
      // " " +
      // ampm;

      return strTime;
    },
  },
};
</script>
